<template>
  <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">工單資料管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="exportBatchReport(1)"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 批次預覽
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
              <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入工單資料" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
          @checkboxChange="onCheckboxChange"
          @checkboxAll="onCheckboxAll"
          @pageChanged="onPageChanged"
        >
          <template #link="{ row }">
            <div @click="linkModal.show(row)" class="inline-block bg-transparent text-blue-500 hover:underline cursor-pointer">
              <p>{{row.JobTickeNo}}</p>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-CustomerId="{ data }">
                <select-box v-bind="customerSelectorOptions" v-model="data.CustomerId" />
              </template>
              <template #column-Remark="{ data }">
                <string-select-box v-bind="remarkSelectorOptions" v-model="data.Remark" />
              </template>
              <template #column-Item="{ data }">
                <div class="grid grid-cols-6 gap-2">
                  <div class="col-span-5">
                    <select-box ref="itemSelectBox" v-bind="ItemSelectorOptions" :focusRefresh="true" v-model="data.ItemId" />
                  </div>
                  <div>
                    <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="addItem(data)">
                      確認
                    </button>
                  </div>
                </div>
              </template>
              <template #column-JobTickeItem="{ data }">
                <JobTickeItemTable ref="itemsTable" :aid="data.Id" @refresh="refresh" :item="data.Item" :data="data.Items" :customerId="data.CustomerId" @updateTotal="updateTotal(data)" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <div class="flex justify-between">
              <div>
                <vxe-button v-if="row.Id && status === 0" type="button" status="danger" content="進行簽核" @click="toDocument(row)"></vxe-button>
                <vxe-button v-if="row.Id" type="button" status="primary" content="匯出" @click="exportExcel(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="status !== 0" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
                <vxe-button v-if="status === 0" type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
                <vxe-button v-if="status === 0" type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
              </div>
            </div>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <div class="flex justify-center">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="grid.edit(row)">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white" title="匯出" @click.stop="exportReport(row)">
                <FontAwesome icon="download" class="w-4 h-4" />
              </button>
              <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
                @click.stop="grid.remove(row)">
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>
          <template #content="{ row }">
            <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center" colspan="4">工號：{{ row.JobTickeNo }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶稅別</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.CustomerTaxType }}</td> -->
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">接單日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.OrderDate ? formatDate(row.OrderDate, 'yyyy/MM/dd') : "" }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Customer?.Number }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">訂單號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.OrderNumber }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">櫃型</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ContainerType }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">工廠交貨日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.DeliveryDate ? formatDate(row.DeliveryDate, 'yyyy/MM/dd') : "" }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                    <td class="border border-gray-400 py-2 px-4">{{ Object.values(model.enums.JobTickeStatus).find(e => e.Value === row.Status)?.Name }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶工廠交貨日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.CustomerDeliveryDate ? formatDate(row.CustomerDeliveryDate, 'yyyy/MM/dd') : "" }}</td>

                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Remark }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">箱/卷數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TotleRadixQuantity }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TotleOrderQuantity }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總重量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TotalWeight }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Pallets }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總金額</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(row.TotleAmount, 2) }}</td>
                  </tr> -->
                </tbody>
              </table>
              <!-- <div class="mt-5 ml-1 mb-1">項目資料：</div> -->
              <!-- <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">單位價格</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總金額</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">Totle G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">Totle N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總重量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">CBM (立方米)</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顯示厚度</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in row.Items" :key="item.Id">
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.UnitPrice) }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.TotleAmount, 2) }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.GW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleGW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.NW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleNW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotalWeight }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.CBM }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.ThicknessDisplay == true ? '是' : item.ThicknessDisplay == false ? '否' : '不選擇' }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal ref="linkVXEModal" v-model="linkModal.visible" fullscreen title="顯示工單資料" show-zoom="true">
          <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center" colspan="4">工號：{{ linkModal.selectedRow?.JobTickeNo }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶稅別</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.CustomerTaxType }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">接單日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.OrderDate ? formatDate(linkModal.selectedRow?.OrderDate, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Customer?.Name }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">訂單號碼</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.OrderNumber }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">櫃型</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ContainerType }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">工廠交貨日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.DeliveryDate ? formatDate(linkModal.selectedRow?.DeliveryDate, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶工廠交貨日期</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.CustomerDeliveryDate ? formatDate(linkModal.selectedRow?.CustomerDeliveryDate, 'yyyy/MM/dd') : "" }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                    <td class="border border-gray-400 py-2 px-4">{{ Object.values(model.enums.JobTickeStatus).find(e => e.Value === linkModal.selectedRow?.Status)?.Name }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Remark }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">箱/卷數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TotleRadixQuantity }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TotleOrderQuantity }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總重量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TotalWeight }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Pallets }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總金額</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(linkModal.selectedRow?.TotleAmount, 2) }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-5 ml-1 mb-1">項目資料：</div>
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">規格</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顏色</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">管芯</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">標頭紙</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">包裝方式</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                    <!-- <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">單位價格</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總金額</th> -->
                    <!-- <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">Totle G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">Totle N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總重量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">CBM (立方米)</th> -->
                    <!-- <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顯示厚度</th> -->
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in linkModal.selectedRow?.Items" :key="item.Id">
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductSize }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.Color }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.PaperTube }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.TitlePaper }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.Package }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                    <!-- <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td> -->
                    <!-- <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.UnitPrice) }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.TotleAmount, 2) }}</td> -->
                    <!-- <td class="border border-gray-400 py-2 px-4">{{ item.GW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleGW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.NW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleNW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotalWeight }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.CBM }}</td> -->
                    <!-- <td class="border border-gray-400 py-2 px-4">{{ item.ThicknessDisplay == true ? '是' : item.ThicknessDisplay == false ? '否' : '不選擇' }}</td> -->
                    <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import JobTickeItemTable from "@/components/import-data/JobTickeItemTable.vue";
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import StringSelectBox, { StringSelectBoxOptions } from '@/cloudfun/components/SelectStringBox.vue'
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    JobTickeItemTable,
    loading: VueLoading,
    StringSelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("JobTickeCreate");
    const canUpdate = checkPermissions("JobTickeUpdate");
    const canDelete = checkPermissions("JobTickeDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '工單資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: true,
      expand: true,
      printConfig: { sheetName: '工單資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '工單資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      {
          field: 'JobTickeSequence',
          title: '工單序號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          width: 100,
          // slots: { default: 'link', }
        },
        {
          field: 'JobTickeNo',
          title: '工號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          width: 100,
          slots: { default: 'link', }
        },
        {
          field: 'OrderDate',
          title: '接單日期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Customer.Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'OrderNumber',
          title: '訂單號碼',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'DeliveryDate',
          title: '工廠交期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('jobTicke/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('jobTicke/query') : undefined,
        save: model ? (params) => model.dispatch('jobTicke/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'JobTickeNo',
          title: '工號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生' } }
        },
        {
          field: 'CustomerTaxType',
          title: '客戶稅別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'OrderDate',
          title: '接單日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        // {
        //   field: 'CustomerNumber',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },       
        {
          field: 'OrderNumber',
          title: '訂單號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'DeliveryDate',
          title: '工廠交貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'CustomerDeliveryDate',
          title: '客戶工廠交貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },        
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.JobTickeStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          slots: { default: "column-Remark" }
        },
        // {
        //   field: 'Remark',
        //   title: '備註',
        //   span: 24,
        //   itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        // },
        // {
        //   title: '選擇項目',
        //   span: 24,
        //   slots: { default: 'column-Item' }
        // },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-JobTickeItem' }
        },
        {
          field: 'TotleQuantity',
          title: '總箱數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleOrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        // {
        //   field: 'TotleQuantity',
        //   title: '總數量',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },        
        {
          field: 'TotalWeight',
          title: '總重量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        // {
        //   field: 'Totle',
        //   title: '總計',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        {
          field: 'TotleAmount',
          title: '總金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        // JobTickeNo: [{ required: true }],
        CustomerId: [{ required: true }],
        Email: [{ message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
      }
    }

    const itemsTable = ref<any>();
    const formSubmit = async(row: any, submit: any) => {
      // var checkNo = await model!.dispatch('jobTicke/exportExcel', row.Id);
      var items = itemsTable?.value.getData();
      console.log(items)
      if(items)
        row.Items = items;
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const itemSelectBox = ref<any>();
    const ItemSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "ProformaInvoice.ProformaInvoiceNumber",
          title: "PI NO",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      formatText: (row) => row.ProformaInvoice && row.Product?.ProductNo && row.Product?.ProductName ? `${row.ProformaInvoice.ProformaInvoiceNumber ?? ''}-${row.Product.ProductNo ?? ''}-${row.Product.ProductName ?? ''}` : row.ProformaInvoice.ProformaInvoiceNumber,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("proformaInvoiceItem/find", value), // eslint-disable-line
        query: async (params) => {
          var items = itemsTable?.value?.getData();
          const list = items?.map((e: any) => e.ProductNo);
          // console.log(list)
          if(params && list && list.length) {
            // console.log(list.join())
            params.condition = params.condition!.and("ProductNo", Operator.NotContains, list.join());
          }
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return await model!.dispatch("proformaInvoiceItem/query", params) // eslint-disable-line
        }
      }
    };

    const addItem = async (row: any) => {      
      try {
        var item = await model!.dispatch("proformaInvoiceItem/find", row.ItemId);
        if(item?.Id) {
          row.Item = item;
          row.ItemId = null;
        }
      } catch (error: any) {
        CloudFun.send('error', { subject: '執行失敗', content: error })
      }
    }

    const initData = (row: any, callback: any) => {
      row.TotleRadixQuantity = 0;
      row.TotleOrderQuantity = 0;
      row.TotleQuantity = 0;
      row.TotalWeight = 0;
      row.Totle = 0;
      row.Status = 0;
      row.Items = [] as any;
      callback();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('jobTicke/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "工單資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const status = ref(0);
    const onGridEdit = async (row: any, callback: any) => {
      status.value = row.Status;
      callback();
    }

    const toDocument = async (row: any) => {      
      if(row.Id) {
        try {
          await model?.dispatch('jobTicke/jobTicketToDocument', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生簽核文件`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const remarkSelectorOptions: StringSelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Remark",
      valueField: "Id",
      columns: [
        {
          field: "Remark",
          title: "備註",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: async (params: any) => {
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          params.condition = new Condition("Remark", Operator.IsNotNull).and(params.condition!);
          var result = await model!.dispatch("jobTicke/query", params);
          result.data = result.data.map((e:any) => { return { Remark: e.Remark, Id: e.Remark } });
          if(result.data.length == 0) {
            result.data = [{ Remark: params.keyword, Id: params.keyword }]
          }
          return result; // eslint-disable-line
        }
      },      
    };

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      return data.map((e: any) => {
          return e[prop] ?? 0;
        })
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.TotleQuantity = sumItems(items, 'Quantity'); //總箱數
        data.TotleRadixQuantity = sumItems(items, 'ItemRollQuantity'); //總卷數
        data.TotleOrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數        
        // data.TotleQuantity = sumItems(items, 'TotleQuantity'); //總數量
        data.TotalWeight = sumItems(items, 'TotalWeight'); //總重量
        data.TotleAmount = formatResultTotalAmount(sumItems(items, 'TotleAmount')); //金額總計
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
        // data.Totle = sumItems(items, 'Totle'); //總計
        // data.Quantity = sumItems(items, 'Quantity'); //箱數
        // data.RollQuantity = sumItems(items, 'RollQuantity'); //卷數
      }
    }

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/JobTickeReportView?jobId=${row.Id}`)
    }

    const exportBatchReport = (row: any) => {
      //替換API_URL
      console.log(selectedRows.value);
      window.open(`https://report.imp.cloudfun.app/api/ReportView/JobTickeReportView?jobId=${selectedRows.value}`)
    }
    const selectedRows = ref<any[]>([]);
    const onPageChanged = async() => {
      // console.log(selectedRows.value)
      if (selectedRows.value && selectedRows.value.length) {
        const data = grid.value.getData();
        for (const i of selectedRows.value) {
          var exist = data.find((e:any) => e.Id == i);
          if(exist) {
            grid.value.setCheckboxRow(exist, true);
          }
        }
      }
    }
    
    const onCheckboxChange =  async({ checked, records, reserves, row }: any) => {
      if(checked) {
        selectedRows.value.push(row.Id);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i != row.Id);
      }
      // console.log(selectedRows.value)
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e === record.Id);
          if (!exist) selectedRows.value.push(record.Id);
        }
      } else {
        const data = grid.value.getData();
        for (const record of data) {
          selectedRows.value = selectedRows.value.filter((i:any) => i != record.Id);
        }
      }
      // console.log(selectedRows.value)
    }
    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      ItemSelectorOptions,
      initData,
      formSubmit,
      itemsTable,
      canCreate,
      addItem,
      itemSelectBox,
      exportExcel,
      isLoading,
      status,
      onGridEdit,
      toDocument,
      customerSelectorOptions,
      updateTotal,
      remarkSelectorOptions,
      exportReport,
      exportBatchReport,
      formatAmount,
      formatDate,
      linkModal,
      linkVXEModal,
      onPageChanged,
      selectedRows,
      onCheckboxChange,
      onCheckboxAll
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
