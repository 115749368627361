
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import JobTickeItemTable from "@/components/import-data/JobTickeItemTable.vue";
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import StringSelectBox, { StringSelectBoxOptions } from '@/cloudfun/components/SelectStringBox.vue'
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    JobTickeItemTable,
    loading: VueLoading,
    StringSelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("JobTickeCreate");
    const canUpdate = checkPermissions("JobTickeUpdate");
    const canDelete = checkPermissions("JobTickeDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '工單資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: true,
      expand: true,
      printConfig: { sheetName: '工單資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '工單資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      {
          field: 'JobTickeSequence',
          title: '工單序號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          width: 100,
          // slots: { default: 'link', }
        },
        {
          field: 'JobTickeNo',
          title: '工號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          width: 100,
          slots: { default: 'link', }
        },
        {
          field: 'OrderDate',
          title: '接單日期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Customer.Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'OrderNumber',
          title: '訂單號碼',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'DeliveryDate',
          title: '工廠交期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('jobTicke/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('jobTicke/query') : undefined,
        save: model ? (params) => model.dispatch('jobTicke/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'JobTickeNo',
          title: '工號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生' } }
        },
        {
          field: 'CustomerTaxType',
          title: '客戶稅別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'OrderDate',
          title: '接單日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        // {
        //   field: 'CustomerNumber',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },       
        {
          field: 'OrderNumber',
          title: '訂單號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'DeliveryDate',
          title: '工廠交貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'CustomerDeliveryDate',
          title: '客戶工廠交貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },        
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.JobTickeStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          slots: { default: "column-Remark" }
        },
        // {
        //   field: 'Remark',
        //   title: '備註',
        //   span: 24,
        //   itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        // },
        // {
        //   title: '選擇項目',
        //   span: 24,
        //   slots: { default: 'column-Item' }
        // },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-JobTickeItem' }
        },
        {
          field: 'TotleQuantity',
          title: '總箱數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleOrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        // {
        //   field: 'TotleQuantity',
        //   title: '總數量',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },        
        {
          field: 'TotalWeight',
          title: '總重量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        // {
        //   field: 'Totle',
        //   title: '總計',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        {
          field: 'TotleAmount',
          title: '總金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        // JobTickeNo: [{ required: true }],
        CustomerId: [{ required: true }],
        Email: [{ message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
      }
    }

    const itemsTable = ref<any>();
    const formSubmit = async(row: any, submit: any) => {
      // var checkNo = await model!.dispatch('jobTicke/exportExcel', row.Id);
      var items = itemsTable?.value.getData();
      console.log(items)
      if(items)
        row.Items = items;
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const itemSelectBox = ref<any>();
    const ItemSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "ProformaInvoice.ProformaInvoiceNumber",
          title: "PI NO",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      formatText: (row) => row.ProformaInvoice && row.Product?.ProductNo && row.Product?.ProductName ? `${row.ProformaInvoice.ProformaInvoiceNumber ?? ''}-${row.Product.ProductNo ?? ''}-${row.Product.ProductName ?? ''}` : row.ProformaInvoice.ProformaInvoiceNumber,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("proformaInvoiceItem/find", value), // eslint-disable-line
        query: async (params) => {
          var items = itemsTable?.value?.getData();
          const list = items?.map((e: any) => e.ProductNo);
          // console.log(list)
          if(params && list && list.length) {
            // console.log(list.join())
            params.condition = params.condition!.and("ProductNo", Operator.NotContains, list.join());
          }
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return await model!.dispatch("proformaInvoiceItem/query", params) // eslint-disable-line
        }
      }
    };

    const addItem = async (row: any) => {      
      try {
        var item = await model!.dispatch("proformaInvoiceItem/find", row.ItemId);
        if(item?.Id) {
          row.Item = item;
          row.ItemId = null;
        }
      } catch (error: any) {
        CloudFun.send('error', { subject: '執行失敗', content: error })
      }
    }

    const initData = (row: any, callback: any) => {
      row.TotleRadixQuantity = 0;
      row.TotleOrderQuantity = 0;
      row.TotleQuantity = 0;
      row.TotalWeight = 0;
      row.Totle = 0;
      row.Status = 0;
      row.Items = [] as any;
      callback();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('jobTicke/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "工單資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const status = ref(0);
    const onGridEdit = async (row: any, callback: any) => {
      status.value = row.Status;
      callback();
    }

    const toDocument = async (row: any) => {      
      if(row.Id) {
        try {
          await model?.dispatch('jobTicke/jobTicketToDocument', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生簽核文件`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const remarkSelectorOptions: StringSelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Remark",
      valueField: "Id",
      columns: [
        {
          field: "Remark",
          title: "備註",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: async (params: any) => {
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          params.condition = new Condition("Remark", Operator.IsNotNull).and(params.condition!);
          var result = await model!.dispatch("jobTicke/query", params);
          result.data = result.data.map((e:any) => { return { Remark: e.Remark, Id: e.Remark } });
          if(result.data.length == 0) {
            result.data = [{ Remark: params.keyword, Id: params.keyword }]
          }
          return result; // eslint-disable-line
        }
      },      
    };

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      return data.map((e: any) => {
          return e[prop] ?? 0;
        })
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.TotleQuantity = sumItems(items, 'Quantity'); //總箱數
        data.TotleRadixQuantity = sumItems(items, 'ItemRollQuantity'); //總卷數
        data.TotleOrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數        
        // data.TotleQuantity = sumItems(items, 'TotleQuantity'); //總數量
        data.TotalWeight = sumItems(items, 'TotalWeight'); //總重量
        data.TotleAmount = formatResultTotalAmount(sumItems(items, 'TotleAmount')); //金額總計
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
        // data.Totle = sumItems(items, 'Totle'); //總計
        // data.Quantity = sumItems(items, 'Quantity'); //箱數
        // data.RollQuantity = sumItems(items, 'RollQuantity'); //卷數
      }
    }

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/JobTickeReportView?jobId=${row.Id}`)
    }

    const exportBatchReport = (row: any) => {
      //替換API_URL
      console.log(selectedRows.value);
      window.open(`https://report.imp.cloudfun.app/api/ReportView/JobTickeReportView?jobId=${selectedRows.value}`)
    }
    const selectedRows = ref<any[]>([]);
    const onPageChanged = async() => {
      // console.log(selectedRows.value)
      if (selectedRows.value && selectedRows.value.length) {
        const data = grid.value.getData();
        for (const i of selectedRows.value) {
          var exist = data.find((e:any) => e.Id == i);
          if(exist) {
            grid.value.setCheckboxRow(exist, true);
          }
        }
      }
    }
    
    const onCheckboxChange =  async({ checked, records, reserves, row }: any) => {
      if(checked) {
        selectedRows.value.push(row.Id);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i != row.Id);
      }
      // console.log(selectedRows.value)
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e === record.Id);
          if (!exist) selectedRows.value.push(record.Id);
        }
      } else {
        const data = grid.value.getData();
        for (const record of data) {
          selectedRows.value = selectedRows.value.filter((i:any) => i != record.Id);
        }
      }
      // console.log(selectedRows.value)
    }
    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      ItemSelectorOptions,
      initData,
      formSubmit,
      itemsTable,
      canCreate,
      addItem,
      itemSelectBox,
      exportExcel,
      isLoading,
      status,
      onGridEdit,
      toDocument,
      customerSelectorOptions,
      updateTotal,
      remarkSelectorOptions,
      exportReport,
      exportBatchReport,
      formatAmount,
      formatDate,
      linkModal,
      linkVXEModal,
      onPageChanged,
      selectedRows,
      onCheckboxChange,
      onCheckboxAll
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
